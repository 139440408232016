<template>
  <div>
    <quiz
      v-if="$route.params.game === 'quiz'"
      @fetchQuestion="fetchQuestion"
      @answerSelected="checkAnswer"
      @resetQuiz="resetQuiz"
      :question="question"
      :selected="selected"
      :questionsAnswered="questionsAnswered"
      :answered="answered"
    />
    <recall
      v-else-if="$route.params.game === 'recall'"
      @fetchStatement="fetchStatement"
      @answerSelected="checkAnswer"
      @resetQuiz="resetQuiz"
      :statement="statement"
      :selected="selected"
      :questionsAnswered="questionsAnswered"
    />
  </div>
</template>

<script>
import Quiz from '@/components/Quiz';
import Recall from '@/components/Recall';

export default {
  name: 'Singleplayer',
  components: {
    Quiz,
    Recall,
  },
  data() {
    return {
      selected: null,
      showInformation: true,
      questionsAnswered: {
        correct: 0,
        wrong: 0,
        count: 0,
      },
      processing: false,
      answered: false,
    };
  },
  computed: {
    question() {
      return this.$store.state.question;
    },
    statement() {
      return this.$store.state.statement;
    },
  },
  methods: {
    fetchQuestion() {
      this.$store.dispatch('fetchQuestion');
    },
    fetchStatement() {
      this.$store.dispatch('fetchStatement');
    },
    checkAnswer(answer, index, type) {
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.selected = index;
      this.answered = true;

      if (answer?.correct) {
        this.questionsAnswered.correct++;
      } else {
        this.questionsAnswered.wrong++;
      }
      if (this.questionsAnswered.count <= 9) {
        setTimeout(() => {
          type === 'question' ? this.fetchQuestion() : this.fetchStatement();
          this.selected = null;
          this.processing = false;
          this.questionsAnswered.count++;
          this.answered = false;
        }, 3000);
      }
      if (this.questionsAnswered.count >= 9) {
        this.calculatePoints();
      }
    },
    resetQuiz() {
      this.selected = null;
      this.questionsAnswered = { correct: 0, wrong: 0, count: 0 };
    },
    calculatePoints() {
      let score = this.questionsAnswered.correct * 10;
      let userId = this.$store.state.user.id;
      this.$store.dispatch('setScore', { score, userId });
      this.processing = false;
    },
  },
};
</script>

<style></style>
