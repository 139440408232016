<template>
  <div>
    <div v-if="showInformation && questionsAnswered.count <= 9">
      <div>
        <h1>Quiz</h1>
        <p>
          Ken jij al je collega's?<br />
          Test je kennis en kies het juiste antwoord om punten te verdienen!
        </p>
      </div>
      <base-button
        :label="'Begrepen'"
        @click.native="showInformation = !showInformation"
      ></base-button>
    </div>
    <div
      :class="$style.quiz"
      v-else-if="!showInformation && questionsAnswered.count <= 9"
    >
      <label :class="$style.amount">{{ questionsAnswered.count + 1 }}/10</label>
      <label :class="$style.label">{{ question.title }}</label>
      <base-button
        :class="[
          $style.button,
          { correct: answered !== false ? answer.correct : false },
          { wrong: answered !== false ? !answer.correct : false },
          { selected: selected === index },
          { shake: selected === index && !answer.correct && answered === true },
        ]"
        :label="answer.label.toString()"
        v-for="(answer, index) in question.answers"
        :key="index"
        @click.native="$emit('answerSelected', answer, index, 'question')"
      />
    </div>
    <div v-else-if="questionsAnswered.count >= 9">
      <base-final-result
        @resetQuiz="resetQuiz"
        :questionsAnswered="questionsAnswered"
      />
      <base-button :label="'Terug'" @click.native="backToHome"></base-button>
      <base-button
        :class="$style.buttonend"
        :label="'Opnieuw'"
        @click.native="resetQuiz"
      ></base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseFinalResult from '@/components/BaseFinalResult.vue';

export default {
  name: 'Quiz',
  components: {
    BaseButton,
    BaseFinalResult,
  },
  props: {
    question: { type: Object, required: false },
    allAnswered: { type: Boolean, required: false },
    selected: { type: Number, required: false },
    questionsAnswered: { type: Object, required: true },
    answered: { type: Boolean, required: false },
  },
  data() {
    return {
      showInformation: true,
    };
  },
  created() {
    this.fetchQuestion();
  },
  methods: {
    fetchQuestion() {
      this.$emit('fetchQuestion');
    },
    resetQuiz() {
      this.showInformation = true;
      this.$emit('resetQuiz');
    },
    backToHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" module>
.quiz {
  display: flex;
  flex-direction: column;

  .amount {
    margin-top: $spacing-small;
  }

  .label {
    font-size: 1.25rem;
    margin: $spacing-large;
  }
  .button {
    margin: $spacing-small;
  }
}
.buttonend {
  margin-left: $spacing-small;
}
</style>
<style>
.selected {
  background: rgb(97, 97, 97);
}
.wrong {
  background: red;
}
.correct {
  background: green;
}

.shake {
  animation: shake 0.75s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
