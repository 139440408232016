<template>
  <div>
    <div v-if="questionsAnswered.correct > 5">
      <h1>Goed gedaan!</h1>
      <p>
        Je hebt {{ questionsAnswered.correct }} uit de
        {{ questionsAnswered.count }} goed!
      </p>
      <p>Je hebt {{ questionsAnswered.correct * 10 }} punten gekregen.</p>
    </div>
    <div v-else>
      <h1>Volgende keer beter</h1>
      <p>
        Je hebt {{ questionsAnswered.correct }} uit de
        {{ questionsAnswered.count }} goed.
      </p>
      <p>Je hebt {{ questionsAnswered.correct * 10 }} punten gekregen.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseFinalResult',
  props: {
    questionsAnswered: { type: Object, required: false },
  },
};
</script>
