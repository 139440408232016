<template>
  <div>
    <div v-if="showInformation">
      <div>
        <h1>Recall</h1>
        <p>
          Herken jij al je collega's?<br />
          Test je kennis en kies het juiste antwoord binnen de tijd om punten te
          verdienen!
          <br />
          Kies de collega die bij de informatie past.
        </p>
      </div>
      <base-button
        :label="'Begrepen'"
        @click.native="showInformation = !showInformation"
      ></base-button>
    </div>
    <div
      v-else-if="!showInformation && questionsAnswered.count < 10"
      :class="$style.recall"
    >
      <label :class="$style.amount">{{ questionsAnswered.count + 1 }}/10</label>
      <base-timer
        :class="$style.timer"
        :timeLimit="timeLeft"
        :stop="stopTimer"
        :key="timerKey"
        @timeUp="$emit('answerSelected', undefined, 5, 'statement')"
      />
      <label :class="$style.label">{{ statement.title }}</label>
      <base-button
        :class="[
          $style.button,
          { correct: selected != null ? answer.correct : false },
          { wrong: selected != null ? !answer.correct : false },
          { shake: selected === index && !answer.correct },
        ]"
        :label="`${answer.firstname} ${answer.middlename} ${answer.lastname}`"
        v-for="(answer, index) in statement.answers"
        :key="index"
        @click.native="answerSelected(answer, index)"
      />
    </div>
    <div v-else-if="questionsAnswered.count >= 10">
      <base-final-result
        @resetQuiz="resetQuiz"
        :questionsAnswered="questionsAnswered"
      />
      <base-button :label="'Terug'" @click.native="backToHome"></base-button>
      <base-button
        :class="$style.buttonend"
        :label="'Opnieuw'"
        @click.native="resetQuiz"
      ></base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/BaseButton.vue';
import BaseTimer from '@/components/BaseTimer.vue';
import BaseFinalResult from '@/components/BaseFinalResult';

export default {
  name: 'Recall',
  components: {
    BaseButton,
    BaseTimer,
    BaseFinalResult,
  },
  props: {
    statement: { type: Object, required: false },
    selected: { type: Number, required: false },
    questionsAnswered: { type: Object, required: false },
  },
  data() {
    return {
      answerClicked: false,
      timesUp: false,
      showInformation: true,
      processing: false,
      timeLeft: 15,
      stopTimer: false,
      timerKey: 10,
    };
  },
  created() {
    this.fetchStatement();
  },
  methods: {
    fetchStatement() {
      this.$store.dispatch('fetchStatement');
    },
    resetQuiz() {
      this.showInformation = true;
      this.$emit('resetQuiz');
    },
    backToHome() {
      this.$router.push('/');
    },
    answerSelected(answer, index) {
      this.stopTimer = true;
      this.$emit('answerSelected', answer, index, 'statement');
    },
  },
  watch: {
    statement() {
      this.stopTimer = false;
      this.timerKey++;
    },
  },
};
</script>

<style lang="scss" module>
.recall {
  display: flex;
  flex-direction: column;

  .timer {
    align-self: center;
  }

  .amount {
    margin-top: $spacing-small;
  }

  .label {
    font-size: 1.25rem;
    margin: $spacing-default;
  }

  .buttons {
    margin-bottom: $spacing-default;
  }

  .button {
    margin: $spacing-small;
  }
}
.buttonend {
  margin-left: $spacing-small;
}
</style>
<style>
.wrong {
  background: red;
}
.correct {
  background: green;
}

.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}
</style>
