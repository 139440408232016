<template>
  <div :class="$style.baseTimer">
    <svg
      class="base-timer__svg"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g :class="$style.circle">
        <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45" />
        <path
          :stroke-dasharray="circleDasharray"
          :class="$style.pathremaining"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        />
      </g>
    </svg>
    <span :class="$style.label">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
export default {
  name: 'BaseTimer',
  props: {
    timeLimit: {
      Type: Number,
      required: true,
    },
    stop: {
      Type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      timerInterval: null,
      timePassed: 0,
    };
  },
  computed: {
    circleDasharray() {
      return `${(this.timeFraction * 283).toFixed(0)} 283`;
    },
    formattedTimeLeft() {
      const minutes = Math.floor(this.timeLeft / 60);
      let seconds = this.timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    timeLeft() {
      return this.timeLimit - this.timePassed;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.timeLimit;
      if (rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction) > 0) {
        return rawTimeFraction - (1 / this.timeLimit) * (1 - rawTimeFraction);
      } else {
        return 0;
      }
    },
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.onTimesUp();
      }
    },
    stop() {
      this.stopTimer();
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
      this.$emit('timeUp', true);
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
    },
  },
};
</script>

<style lang="scss" module>
.baseTimer {
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: $spacing-default;

  .svg {
    transform: scaleX(-1);
  }

  .circle {
    fill: none;
    stroke: none;
  }

  .pathremaining {
    stroke-width: 4px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: $primary;
  }

  .label {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }
}
</style>
